<template>
	<div class=""><h1>入驻管理</h1></div>
</template>

<script>
export default {
	data() {
		return {};
	},
	created() {},
	methods: {}
	
	
};
</script>

<style lang="less" scoped></style>
